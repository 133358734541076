<aside class="aside-navbar">
  <div class="aside-navbar-box">
    <div class="close-aside">
      <i class="lnr lnr-cross"></i>
    </div>
    <div class="site-logo">
      <a [routerLink]="['/home/dashboard']">
        <img src="assets/images/gfd-logo.svg" alt="gfd-logo" />
      </a>
    </div>
    <div class="aside-navigation-list">
      <ul>
        <li class="has-sublist" [ngClass]="{
            active: menuID == m.id && m.isExpanded,
            'd-none': !m.isShow
          }" *ngFor="let m of menuList; let i = index" (click)="openSubList(i, m.isExpanded, m.id, $event)">
          <a [routerLink]="m.link" (click)="routeToLink(m.link, m, $event)">
            <span class="icon">
              <img src="{{ m.img }}" alt="image" />
            </span>
            {{ m.name }}
          </a>
          <div>
            <ul class="sublist" id="m.id" *ngIf="m.isExpanded">
              <li *ngFor="let s of m.subList; let ind = index" (click)="subLinkClick(m.isExpanded, m.id, $event)"
                [class.active]="subMenuID == s.subID" [ngClass]="{ 'd-none': !s.isSubShow }">
                <a [routerLink]="s.subLink" (click)="routeToSubLink(s.subLink, s, $event)">
                  <!-- [routerLink]="[s.subLink]" -->
                  {{ s.subName }}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- <div class="help-needed">
        <div class="icon">
            <img src="assets/images/life-jacket-icon.svg" alt="aside-icon">
        </div>
        <div class="content">
            <h5>Need help?</h5>
            <p>Call 971 55 123 4567</p>
        </div>
    </div> -->

  <div class="bottom-nav">
    <div class="logout-link">
      <a href="javascript: void(0)" (click)="generalService.logout()">
        <span class="icon">
          <img src="assets/images/logout-icon.svg" alt="aside-icon" />
        </span>
        Logout
      </a>
    </div>
    <!-- <div class="help-needed">
      <div class="icon">
        <img src="assets/images/life-jacket-icon.svg" alt="aside-icon" />
      </div>
      <div class="content">
        <h5>Need help?</h5>
        <p>Call 971 55 123 4567</p>
      </div>
    </div> -->
  </div>
</aside>